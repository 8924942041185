/* global hiec, gC, sC, cC, gDW, hideAdDiv, adImageEmployersDesktop, adImageEmployersMobile, adImageCloseButton */

if (cC('_hiec')) {
  if (typeof hiec !== 'undefined' && hiec.ads) {
    hiec.ads = JSON.parse(gC('_hiec'));
  }
}

if (hiec.ads.hero.urls.length < hiec.ads.hero.limit) {
  let visited = false;
  for (let i = 0; i < hiec.ads.hero.urls.length; i++) {
    if (hiec.ads.hero.urls[i] === hiec.loc) {
      visited = true;
      break;
    }
  }
  if (!visited && !/^.*\/(donate|civicrm|wp-admin)\/.*$/.test(hiec.loc)) {
    hiec.ads.hero.urls.push(hiec.loc);
    const myCreatives = [
      {
        'ad_url': 'https://www.apprenticesearch.com/employers/sign_up',
        'desktop': {
          'ad_image': adImageEmployersDesktop,
          'ad_image_width': 728,
          'ad_image_height': 90,
        },
        'mobile': {
          'ad_image': adImageEmployersMobile,
          'ad_image_width': 320,
          'ad_image_height': 50,
        },
      },
    ];

    const creativeIndex = Math.floor(Math.random() * myCreatives.length);

    const myAd = new Object();
    myAd.id = 'hero';

    myAd.adUrl = myCreatives[creativeIndex].ad_url;
    if (gDW() < 728) {
      myAd.adImage = myCreatives[creativeIndex].mobile.ad_image;
      myAd.adImageWidth = myCreatives[creativeIndex].mobile.ad_image_width;
      myAd.adImageHeight = myCreatives[creativeIndex].mobile.ad_image_height;
    } else {
      myAd.adImage = myCreatives[creativeIndex].desktop.ad_image;
      myAd.adImageWidth = myCreatives[creativeIndex].desktop.ad_image_width;
      myAd.adImageHeight = myCreatives[creativeIndex].desktop.ad_image_height;
    }

    myAd.adCloseButtonImage = adImageCloseButton;

    myAd.displayTime = 20;

    myAd.externalTrackingPixel = '';

    myAd.transAdBoxWidth = myAd.adImageWidth + 20;
    myAd.transAdBoxHeight = myAd.adImageHeight + 30;
    myAd.transAdBoxLeftMargin = Math.round(myAd.transAdBoxWidth / 2);

    const myAdWrapper = document.createElement('div');
    myAdWrapper.id = myAd.id + 'BannerAdWrapper';
    document.body.appendChild(myAdWrapper);

    // prettier-ignore
    myAd.putObjects = function () {
      document.getElementById(myAdWrapper.id).innerHTML = '\n\n<style type="text/css">\n' +
        '<!--\n' +
        '.' + myAd.id + '_trans_ad_box {\n' +
        '  position: fixed;\n' +
        '  bottom: 0px;\n' +
        '  left: 50%;\n' +
        '  width: ' + myAd.transAdBoxWidth + 'px;\n' +
        '  height: ' + myAd.transAdBoxHeight + 'px;\n' +
        '  margin-left: -' + myAd.transAdBoxLeftMargin + 'px;\n' +
        '  text-align: left;\n' +
        '  border: 1px solid #000;\n' +
        '  clear: both;\n' +
        '  background: rgba(0, 0, 0, 0.65);\n' +
        '  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr="#a5000000", endColorstr="#a5000000");\n' +
        '  z-index: 50000;\n' +
        '}\n' +
        '.' + myAd.id + '_creative_box {\n' +
        '  overflow: hidden;\n' +
        '  position: absolute;\n' +
        '  border: none;\n' +
        '  top: 20px;\n' +
        '  left: 10px;\n' +
        '}\n' +
        '-->\n' +
        '</style>\n' +
        '<div class="' + myAd.id + '_trans_ad_box" id="' + myAd.id + '">\n' +
        '  <div class="' + myAd.id + '_creative_box">\n' +
        '    <a href="' + myAd.adUrl + '"><img src="' + myAd.adImage + '" width="' + myAd.adImageWidth + '" height="' + myAd.adImageHeight + '" style="border: none;"></a>\n' +
        '  </div>\n' +
        '  <div style="position: absolute; border: none; top:2px; right:22px; font: 12px bold verdana, sans-serif; color: #ffffff;">Close </div><a href="javascript: hideAdDiv(\'' + myAd.id + '\')"><img src="' + myAd.adCloseButtonImage + '" width="15" height="15" style="position: absolute; border: none; top: 3px; right: 3px;"></a>\n' +
        '</div>\n' +
        myAd.externalTrackingPixel;
    };

    myAd.putObjects();
    sC('_hiec', JSON.stringify(hiec.ads), 12, 'hr');

    if (!isNaN(myAd.displayTime) && myAd.displayTime / 1 > 0) {
      setTimeout(function () {
        hideAdDiv(myAd.id);
      }, myAd.displayTime * 1000);
    }
  }
}
